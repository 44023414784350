@import '../../../../Config/Theme/primary_theame.scss';
.apply-ef-drawer{
  .select-bus {   
    .check-box{
      display: flex;
      gap:1rem;
    }
    .business-group-top-heading{
      background-color: $primaryGreyLight;
      display: flex;
      padding: 1rem;
      align-items: start;
      gap:5rem;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      p {
        font-size: 1.2rem;
        font-weight: 500;
        color: black;
        margin: 0;
        padding: 0;
      }
    }
    .business-group {
      padding: 1rem;
      p {
        font-size: 1rem;
        font-weight: 400;
        color: black;
        margin: 0;
        padding: 0;
      }
      .business-group-heading {
        width: 170px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;
        
        .switchs {
          transition: all 0.3s ease;
          cursor: pointer;
          font-size: 1rem;
          height: 1rem;
          &.active{
            transform: rotate(90deg);
          }
        }
      }
      .business-units{
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin:1rem 0 0 1rem;
        .business-unit{
          display: grid;
          grid-template-columns: 0.6fr 6fr 6fr 6fr;
          p{
            font-size: 0.9rem;
            color: $primaryGray;
          }
        }
        
        .business-unit-heading{
          background-color:  $primaryGreyLight;
          border-radius: 5px 5px 0 0;
          p{
            color: black;
            padding: 0.5rem;
          } 
        }
      }
    }
  }
}