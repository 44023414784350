@import '../../../../../Config/Theme/primary_theame.scss';


.textbox-parent {
    width: 100%;
    .textbox-question {
        font-size: 1rem;
        font-weight: 600;
        color: $primaryBlack;
        margin-bottom: 1rem;
    }
}