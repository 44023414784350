@import '../../Config/Theme/primary_theame.scss';

.main-tab-container {
    border: 2px solid $primaryGreyLight;
    background-color: white;
    .main-tab {
        display: flex;
        align-items: center;
        height: 4rem;
        font-size: 1rem;
        padding: 0 1rem;
        color: $primaryGray;
        cursor: pointer;
        &.disabled{
            cursor: not-allowed;
        }
        .icon {
            width: 1rem;
            height: 1rem;
            margin-left: auto;
        }
        &.active {
            color: $primaryGreen;
        }
    }
    .sub-tabs-container {
        display: flex;
        padding: 0 1rem 1rem 1rem;
        flex-direction: column;
        .sub-tab {
            display: flex;
            align-items: center;
            height: 3rem;
            border-bottom: 1px solid white;
            font-size: 0.9rem;
            color: $primaryBlack;
            cursor: pointer;
            &.active {
                color: $primaryGreen;
            }
        }
    }
    &.active {
        background-color: $primaryGreenLight;
    }
}
