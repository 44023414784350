@import '../../Config/Theme/primary_theame.scss';

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primaryGreen;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: $primaryGreyLight!important;
  border-color: $primaryGray!important;
}

.drag-table{
   overflow-y: auto;
   margin: 0;
   padding: 0;
}

.drag-table::-webkit-scrollbar {
  width: 0.3rem;
}

.drag-table::-webkit-scrollbar-track {
  background: $primaryGreyLight;
}

.drag-table::-webkit-scrollbar-thumb {
  background: $primaryGray;
}
