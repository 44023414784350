@import '../../../../../../../Config/Theme/primary_theame.scss';

.value-box {
    width: calc(100% - 2rem);
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    margin-top: 1rem;
    flex-direction: row;
    background-color: $primaryGreyLight;
    padding: 0.5rem 1rem;
    box-shadow: 0px 0px 10px 0px rgba(83, 80, 80, 0.1);

    .values{
        padding-left: 1rem;
        width: 100%;
    }
}