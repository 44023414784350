@import '../../../../Config/Theme/primary_theame.scss';

.top-section {
    display: flex;
    align-items: center;
    font-weight: 600;
    gap: 1rem;
    padding: 0 1.5rem 1rem 1.5rem;
    border-bottom: 1px solid $primaryGreyLight;

    .ant-form-item {
        margin-bottom: 0 !important;
    }
}