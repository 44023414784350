@import '../../../../../Config/Theme/primary_theame.scss';


.text-list-parent {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .text-question-container {
        width: 100%;

        .text-list-question {
            font-size: 1rem;
            font-weight: 600;
            color: $primaryBlack;
            margin-bottom: 1rem;
        }

    }
    
}