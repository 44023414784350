@import '../../Config/Theme/primary_theame.scss';

.data-manager{

    .filter-section {
        margin-left: 1.5rem;
        display: flex;
        flex-direction: row;
        gap: 1rem;
        .status-filter-select {
            .ant-select-selector {
              border: 1px solid $primaryGreyLight !important;
              .ant-select-selection-placeholder{
                color: $primaryBlack;
              }
              &:hover{
                border: 1px solid $primaryGreyLight !important;
              }
            }
            width: 150px;
            gap: 0.5rem;
          }
    }

    .active{
        color: $primaryGreen;
    }
    .top-container{
        display: flex;
        align-items: center;
        font-weight: 600;
        gap: 1rem;
        padding: 2rem 1.5rem;  // this is the padding and it should be constant
        padding-top: 0;
        .year{
            // padding-right: 1rem;
            // border-right: 2px solid $primaryGreyLight;
        }
        .ant-form-item{
            margin-bottom: 0!important;
        }
    }
    .data-entry{
        .empty-state-access-roles {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 3rem 0;
        }

        .empty-state {
            background-color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            margin: 2rem 0;
            padding-bottom: 2rem;
            &>div {
              padding: 20px;
              background-color: $primaryGreenLight;
              width: fit-content;
              border-radius: 50%;
              .imgs {
                height: 47px;
                width: 47px;
              }
            }
          }
          .main.empty-state{
            padding: 1rem 0;
          }
        .outer-tabs{
            margin-top: 2rem;
        }
        .inner-tabs{
            background-color: white;
            padding: 1rem 1.5rem;
            padding-right: 1.5rem;
            display: flex;
            .inner-tabs-filter{
                padding-left: 2rem;
            }
        }
        background-color: $primaryGreyLight;
        .status-tag-metrics{
            display: flex;
            align-items: center;
            gap:'0.3rem';
        }
        .kpi-data{
            margin-top: 1rem;
            .right-topic-heading{
                display: flex;
                gap: 1rem;
            }
            .data-manager-logs{
                padding: 1rem;
            }
            .change-status-icon{
                height: 1rem;
                transform: rotate(90deg);
                margin-left: 0.5rem;
            }
            .kpi-heading{
                width: 100%;
                display: flex;
                align-items: center;
                gap: 1rem;
                &>p{
                    font-size: 1rem;
                    font-weight: 600;
                } 
            }
            .metric-data{
                margin-top: 1rem;
                .rejected-row{
                    background-color: $primaryAmberLight;
                }
                .rejected-row:hover{
                    background-color: $primaryAmberLight!important;
                }
                .pending-row{
                    background-color: $primaryYellowLight
                }
                .value-box {
                    width: calc(100% - 2rem);
                    display: flex;
                    align-items: center;
                    border-radius: 0.5rem;
                    margin-top: 1rem;
                    flex-direction: row;
                    background-color: $primaryGreyLight;
                    padding: 0.5rem 1rem;
                    box-shadow: 0px 0px 10px 0px rgba(83, 80, 80, 0.1);

                    .values{
                        padding-left: 1rem;
                        width: 100%;
                    }
                }
                // .values{
                //     height: fit-content;
                //     p{
                //         color: $primaryGray;
                //         font-weight: 600;
                //         padding: 0;
                //         margin: 0;
                //     }
                // }
            }
        }
    }
    
}