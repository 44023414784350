@import "../../../Config/Theme/primary_theame.scss";

.header-type2 {
    background-color: $primaryGreenLight;
    color: $primaryBlack;
    font-weight: 600;
    height: 4rem;
    position: fixed;
    z-index: 2;
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 1.25rem;
    padding: 0 1rem;
    width: 100%;

    .header-type2-right {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-left: auto;
        gap: 1rem;
        flex-direction: row;
    }

    .cancel{
        height: 1rem;
        display: flex;
        align-items: center;
        margin-left: auto;
        cursor: pointer;
    }

}

.secondry-heading-type2{
    margin-top:4rem;
    display: flex;
    width: 100%;
    position: fixed;
    align-items: center;
    padding: 0 1rem;
    &.light{
        color: $primaryGray;
        background-color: $primaryGreyLight;
    }
    &.dark{
        color: $primaryGreyLight;
        background-color: $primaryBlack;
    }
    .sec-head{
        font-weight: 500;
        font-size: 1rem;
    }
    .sec-sub-head{
        font-weight: 400;
        font-size: 1rem;
    }
    .sub-head-right{
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 1rem;
        flex-direction: row;
        padding-right: 2rem;
    }
}

.drawer-type2-body {
    width: 100%;
    margin-top: 8rem;
    height: calc(100vh - 8rem);
    .left-body {
        width: 18%;
        overflow-y: auto ;
        height: calc(100vh - 8rem);
        border-right: 1px solid $primaryGreyLight;
        background-color: #FAFAFA;
    }
    .left-body::-webkit-scrollbar {
        width: 5px;
    }
    .left-body::-webkit-scrollbar-track {
        background: $primaryGreyLight;
        width: 2px;
    }
    .left-body::-webkit-scrollbar-thumb {
        background: $primaryGray;
    }
    .right-body {
        width: 82%;
        .right-scroll {
            overflow-y: auto;
            height: calc(100vh - 12rem);
        }
        .right-scroll::-webkit-scrollbar {
            width: 5px;
        }
        .right-scroll::-webkit-scrollbar-track {
            background: $primaryGreyLight;
            width: 2px;
        }
        .right-scroll::-webkit-scrollbar-thumb {
            background: $primaryGray;
        }
        .footer {
            height: 4rem;
            position: fixed;
            display: flex;
            padding: 0 1rem;
            flex-direction: row;
            bottom: 0;
            width: calc(82% - 2rem);
            align-items: center;
            background-color: black;
            .footer-right {
                margin-left: auto;
                display: flex;
                align-items: center;
                gap: 1rem;
                flex-direction: row;
            }
        }
    }
}