@import "../../Config/Theme/primary_theame.scss";

.upload-box,
.upload-box-fail {
  height: 186px;
  width: 330px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 10px;
  border: 2px dashed $secondaryGreenDark;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  .loading{
    display: flex;
    flex-direction: column;
    .uploading-text{
      color: $primaryGreen;
      margin-top: 1rem;
    }
  }

  .text {
    font-size: 18px;
    color: $secondaryGreenDark;
    margin: 0;
    text-align: center;
  }

  .hint {
    font-size: 14px;
    color: $primaryGray;
    margin: 0.4rem;
    text-align: center;
  }
  .hint-fail {
    font-size: 14px;
    color: $primaryRedError;
    text-align: center;
  }
  .icon {
    width: 2rem;
    height: 2rem;
  }

  .browse-file {
    padding: 10px 20px;
    border-radius: 10px;
    border: 3px solid $primaryGray;
    font-weight: 700;
  }
}

.input {
  visibility: hidden !important;
  height: 0;
}

.upload-box-fail {
  border-color: $primaryRedError;
}

.delete-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 5;
  cursor: pointer;
  height: 1.4rem;
}

.drag-box {
  background-color: $primaryGreenLight;
}
