@import '../../../Config/Theme/primary_theame.scss';

.step-collapse{
    width: 100%;
    border: 1px solid $primaryGreyLight;
    border-radius: 8px;
    .heading{
        padding-right: 4rem;
        padding-left: 1rem;
        display: flex;
        align-items: flex-start;
        padding: 0.5rem 1rem;
        background-color: $primaryGreyLight;
        position:relative;
        .step{
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            height: 28px;
            width: 28px;
            color: white;
            background-color: $primaryBlack;
            margin-right: 1rem;
            // margin-top: 0.4rem;
        }
        .main{
            font-weight: 600;
            font-size: 1.5rem;
            color: $primaryBlack;
            margin: 0;
        }
        .sub{
            font-size: 1rem;
        }
        .switch-arrow{
            cursor: pointer;
            position: absolute;
            right: 1rem;
            transform: rotateZ(90deg);
            transition: all 0.2s ease;
        }
    }
    .body-collapse{
    }
}