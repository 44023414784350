.sample-download{
	display: flex;
	justify-content: flex-end;
	margin-top: 1rem;
	gap: 0.3rem;
	align-items: center;
	.icon{
	  width: 1rem;
	  height: 1rem;
	}
	p{
		margin: 0;
	}
}