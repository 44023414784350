.vault-folder{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  width: 66px;
  .vault-folder_icon{
    img{
      height: 53px;
    }
  }
  .vault-folder_name{
    max-width: 100px;
    font-weight: 600;
    padding-top: 0.5rem;
    line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}