@import '../../../../Config/Theme/primary_theame.scss';

.mob-total-metric-summary{
	display: flex;
	justify-content: space-between;
	background-color: $primaryGreyLight;
	border-radius: 7px;
	padding: 10px;
	margin-bottom: 2rem;
	h2{
		font-weight: 500;
		padding-bottom: 5px;
	}
	.total-value{
		border-right: 1px solid #DDDDDD;
		width: 50%;
	}
	.approved-value{
		text-align: center;
		width: 50%;
	}
}