@import "../../../Config/Theme/primary_theame.scss";

.nav-banner {
  background-color: $primaryBlack;
  color: $primaryGreyLightest;
  padding: 0 2rem;
  height:81px;
  font-size: 16px;
  line-height: 24px;
  // border-top: 0.2px solid rgb(73, 73, 73);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list::-webkit-scrollbar {
  height: 2px;
  width: 5px;
}
.list::-webkit-scrollbar-track {
  background: #f1f1f1;
  height: 2px;
}
.list::-webkit-scrollbar-thumb {
  background: #707070;
}
