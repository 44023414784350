@import "../../Config/Theme/primary_theame.scss";

.ant-message-notice-content{
    box-shadow: none!important;
}
.ant-message {
    @media screen and (max-width: 768px){
        bottom: 50px!important;
    }
    bottom: 20px!important;
    top: initial!important;
}

p{
    margin: 0;
    padding: 0;
  }
.ant-message-notice-content{
    background-color: transparent!important;
}
.success-message, .error-message, .info-message, .warning-message, .delete-confimation {
    padding: 0.3rem 1rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    box-shadow: 0px 4px 16px 0px $secondaryGray;
    background-color: white;
}

.success-message {
    background-color: $primaryGreen;
    color: white;
}   
.error-message {
    background-color: $primaryRedError;
    color: white;
}
.info-message {
    color: $primaryGray
}
.warning-message {
    background-color: $amber;
    color: white;
}
.delete-confimation {
    display: block;
    width: 300px;
    padding: 1rem;
}