@import '../../Config/Theme/primary_theame.scss';

.ant-picker-cell-today {
  .ant-picker-cell-inner {
    &::before {
      border: none !important;
    }
  }
}
.ant-picker-cell-selected {
  .ant-picker-cell-inner {
    background-color: $primaryGreen !important;
  }
}