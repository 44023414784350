@import '../../../../../../../Config/Theme/primary_theame.scss';
.investigation-body-summary{
	.investigation-body-summary-title {
		font-size: 16px;
		font-weight: 600;
		color: $primaryGray;
	}
}

.event-investigation{
	padding: 1rem;
	.investigation-details-container{
		display: flex;
		gap: 1rem;
		.investigation-team-container{
			height: 98px;
			width: 80%;
			.investigation-team{
				border: 1px solid $primaryGreyLight;
				border-radius: 7px;
				margin: 0.1rem;
				display: flex;
				height: 100%;
				.team-member{
					display: flex;
					align-items: center;
					margin: 0.5rem;
					width: fit-content;
					height: 20px;
					border-radius: 7px;
					padding: 0.1rem 1rem;
					background-color: $primaryGreyLight;
				}
			}
		}
	}

	.empty-investigation-container{
		height: 50vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}