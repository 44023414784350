@import '../../../Config/Theme/primary_theame.scss';

.view-type-container{
  display: flex;
  >div{
    border: 1px solid $primaryGreyLight;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    cursor: pointer;
    >div{
      width: 18px;
      height: 18px;
    }
  }
  .grid-view{
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .list-view{
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}