@import '../../../Config/Theme/primary_theame.scss';

.months-slicer {
  font-size: 0.85rem;
  position: relative;

  .selector-box {
    cursor: pointer;
    height: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $primaryGreyLight;
    padding: 0 2.5rem 0 1rem;
    border-radius: 8px;

    .pipe-right {
      font-weight: 400;
      padding-right: 0.5rem;
      border-right: 2px solid $primaryGreyLight;
    }
  }

  .down-icon {
    position: absolute;
    right: 0.2rem;
    height: 0.5rem;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
  }

  .slicer-box {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    right: 0;
    width: calc(476px - 2rem);
    padding: 1rem;
    border-radius: 8px;
    border: 1px solid $primaryGreyLight;
    background-color: white;
    z-index: 5;

    .hr {
      margin-top: 20px;
      color: rgb(242, 236, 236);
      background-color: rgb(242, 236, 236);
      width: calc(450px - 2rem);
      opacity: 0.1;
    }

    .quarter-box {
      display: flex;
      flex-direction: row;
    }

    .months-box {
      display: flex;

      .month-box {
        margin: 0.75rem 0;
        width: 90px;
      }
    }

    .month {
      font-weight: 400;
    }
  }
}