@import '../../Config/Theme/primary_theame.scss';

.chart-filter {
    padding: 1rem 0.5rem 0.5rem 0.5rem;
    border: 1px solid $primaryGreyLight;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);

    .chart-title {
        margin: 0 0 1rem 0.5rem;
    }

    .filter-container {
        padding: 0 0.5rem;

        .left-filters {
            float: left;

        }

        .right-filters {
            margin-left: auto;
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
        }

    }

    .chart-container {
        .zero-state-chart {
            height: 400px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.5rem;
            color: $primaryGray;
            font-weight: 500;
        }
    }
}