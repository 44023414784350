@import '../../../../Config/Theme/primary_theame.scss';

.emissions-overview {
    .select-scope2{
        padding: 1rem;
        border-radius: 10px;
        background-color: $primaryGreenLightest;
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        gap: 2rem;
        align-items: center;
        .heading{
            font-size: 1.25rem;
            margin-bottom: 0.5rem;
        }
        .description{
            font-size: 0.8rem;
            color: $primaryGray;
        }
    }
    .bordered-container{
        border: 2px solid $primaryGreyLight;
        border-radius: 10px;
    }
    .table-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        margin-bottom: 1rem;
    }
    .chart-title {
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 1rem;
    }
}