.choose-company {
    text-align: center;
    height: 100vh;
    overflow-y: hidden;
    position: relative;

        .companies-box {
            width: 1057px;
            // display: flex;
            // flex-wrap: wrap;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 1rem;
            // justify-content: center;
            margin: 2rem auto;
            height: 350px;
        }

    .company-card {
        cursor: pointer;
        &:hover {
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
        }
    }

    .vector1,
    .vector2 {
        position: absolute;
        width: 100vw;
        bottom: 0;
        right: 50%;
        transform: translateX(50%);
        z-index: -1;
    }
}