@import '../../../../../Config/Theme/primary_theame.scss';
.assign-template {
  .select-bus {   
    .check-box{
      display: flex;
      gap:1rem;
    }
    .business-group-top-heading{
      background-color: $primaryGreyLight;
      display: flex;
      padding: 1rem;
      align-items: start;
      gap:5rem;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      p {
        font-size: 1.2rem;
        font-weight: 500;
        color: black;
        margin: 0;
        padding: 0;
      }
    }
    .business-group {
      display: flex;
      padding: 1rem;
      align-items: start;
      gap:5rem;
      p {
        font-size: 1rem;
        font-weight: 400;
        color: black;
        margin: 0;
        padding: 0;
      }
      .business-group-heading {
        width: 170px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;
        
        .switchs {
          font-size: 1rem;
          height: 1rem;
        }
      }
      .business-units{
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
    }
  }
}