@import "../../Config/Theme/primary_theame.scss";

.be-drawer {
    .children-body {
        overflow-y: auto;
        overflow-x: hidden;
    }

    .children-body::-webkit-scrollbar {
        width: 5px;
    }

    .children-body::-webkit-scrollbar-track {
        background: $primaryGreyLight;
        width: 2px;
    }

    .children-body::-webkit-scrollbar-thumb {
        background: $primaryGray;
    }
    // font-size: 2rem;
    p{
        margin: 0;
        padding: 0;
        // font-weight: 600;
        // color: $primaryGreyDark;
        margin-bottom: 0.5rem;
    }
    .ant-drawer-body {
        padding: 0px !important;
    }

    .drawer-container {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .top-section {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 0 1rem;
        background-color: $primaryGreenLight;
        p {
            font-size: 2rem;
            margin: 0;
            padding: 0;
            font-weight: 600;
            color: $primaryGreyDark;
            margin-bottom: 0.5rem;
        }

        span {
            font-size: 1.5rem;
            font-weight: 600;
            color: $primaryBlack
        }
        &.mobile{
            span {
                font-size: 1.2rem;
                font-weight: 600;
                color: $primaryBlack
            }
        }
        .cancle {
            height: 16.8px;
            display: flex;
            align-items: center;
            margin-left: auto;
            cursor: pointer;
        }
        &.dark {
            background-color: $primaryBlack;
            p {
                color: white;
            }
            span {
                color: $primaryGreyLight;
            }
        }
     
    }

    .footer {
        display: flex;
        align-items: center;
        gap: 1rem;
        border-top: 2px solid $primaryGreyLight;
        padding-left: 1rem;
        &.dark {
            background-color: $primaryBlack;
            color: white;
        }
    }
    .footer-notification{
       
    }
}

.secondry-heading{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-left: 1rem;
    &.light{
        color: $primaryBlack;
        background-color: $primaryGreyLight;
        .sub-heading {
            opacity: 0.5;
        }
    }
    &.dark{
        color: $primaryGreyLight;
        background-color: $primaryBlack;
    }
    span{
        font-weight: 500;
        font-size: 1rem;
    }
    p{
        font-weight: 400;
        font-size: 1rem;
    }

}