.reporting-review-status-btn{
  .inner-box{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    >div{
      //rotate the icon
      transform: rotate(90deg);
    }
  }
  p{
    margin:0;
  }
}