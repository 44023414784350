@import '../../../Config/Theme/primary_theame.scss';
.event-list-item{
	background-color: $primaryGreyLightest;
	padding: 0 0.5rem;
	border-radius: 7px;
	// margin-bottom: 1rem;
	.title{
		font-size: 0.9rem;
		font-weight: 600;
		color: $primaryBlack;
	}
	.row-1{
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: calc(1.5rem - 1px);
		padding: 0.6rem;
		border-bottom: 1px solid $primaryGreyBorder;
	}
	.row-2{
		display: flex;
		height: 1.5rem;
		align-items: center;
		padding: 0.6rem;
		gap: 1rem;
	}
}
.event-list-item-bottom{
	background-color: #F5F5F5;
	border-bottom-right-radius: 7px;
	margin: 0 -0.5rem ;
}