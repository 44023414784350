@import "../../../../../../../Config/Theme/primary_theame.scss";

.top-section-reports {
    position: fixed;
    right: 0;
    display: flex;
    gap: 1rem;
    z-index: 1;
    width: calc(82% - 2rem);
    height: 4rem;
    flex-direction: row;
    align-items: center;
    background-color: white;
    padding: 0 1rem;
    .top-section-title {
        display: flex;
        flex-direction: column;
        .heading {
            font-size: 1.25rem;
            font-weight: 600;
        }
        .sub-heading {
            font-size: 0.75rem;
            color: $primaryGray;
        }
    }
    .select-box{
        width: 150px;
        .ant-form-item{
            margin-bottom: 0!important;
        }
    }
}