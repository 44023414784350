@import '../../../Config/Theme/primary_theame.scss';

.be-button{
    border-radius: 4px!important;
    &.ant-btn-sm{
        font-size: 0.8rem!important;
    }
    &:disabled{
        color: $primaryGray!important;
        opacity: 0.7!important;
    }
    &.primary{
        background-color: $primaryGreen!important;
        color: white!important;
        border: none;
        &:hover{
            box-shadow: 0px 0px 4px 0px  #868686 ;
            color: white!important;
            // background-color: $secondaryGreen!important;
        }
    }  
    &.primary-red{
        background-color: $primaryRedError!important;
        color: white!important;
        border: none;
        &:hover{
            box-shadow: 0px 0px 4px 0px  #868686 ;
            color: white!important;
        }
    }
    &.secondary-yellow{
        border: 1px solid $amber;
        color: $amber;
        background-color: white;
        &:hover{
            color: white!important;
            background-color: $amber!important;
        }
    }
    &.secondary-green{
        border: 1px solid $primaryGreen;
        color: $primaryGreen;
        background-color: white;
        &:hover{
            color: white!important;
            background-color: $primaryGreen!important;
        }

    }
    &:hover{
        box-shadow: 0px 0px 4px 0px  #868686 ;
        border: inherit!important;
    }
    font-weight: 600;
}