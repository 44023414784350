p{
  margin: 0;
  padding: 0;
}

/* radio css */


@media screen and (max-width: 1450px) {
  *{
    font-size:0.85rem;
    size: 85%!important;
  }
  .ant-btn{
    font-size: 0!important;
  }
  h2{
    font-size: 1.3rem!important;
  }
  h1{
    font-size: 2.5rem!important;
  }
}