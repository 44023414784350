@import '../../Config/Theme/primary_theame.scss';

.be-tab-button{
    cursor: pointer;
    color: $primaryGray;
    display: flex;
    align-items: center;
    gap: 5px;
    .icon{
        svg{
            display: flex;
            align-items: center;
            height: 19px;
            width: 19px;
        }
    }
    .active{
        color: $primaryGreen;
    }
}