@import '../../.../../../../Config/Theme/primary_theame.scss';

.tracker-card-dm {
    border-radius: 5px;
    border: 1px solid $primaryGreyLight;
    padding: 1rem;
    width: 350px;

    .tracker-card-progress-container {
        justify-content: center;
        height: 180px;

        .ant-progress-inner .ant-progress-circle-gradient {
            height: 200px;
            position: relative;
        }

        .ant-progress-text {
            top: 100px;
            position: absolute;
            font-size: 1.5rem;
            font-weight: 500;
            color: $primaryBlack;
        }

    }

    .tracker-card-info-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .tracker-card-info {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            gap: 0.5rem;

            .tracker-card-info-title {
                font-size: 1rem;
                color: $primaryGray;
            }

            .tracker-card-info-value {
                font-size: 1rem;
                color: $primaryBlack;
                background-color: $primaryGreyLight;
                border-radius: 5px;
                padding: 0.5rem;
            }
        }
    }
}