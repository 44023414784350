@import '../../Config/Theme/primary_theame.scss';

.top-bar{
    background-color: $primaryBlack;
    color: white;
    height: 64px;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    .logo,.user-icon{
        height: 31px;
    }
}

.right-div{
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}