@import '../../../../../../../../../../../Config/Theme/primary_theame.scss';
.modal{
	color: $primaryGray;
	h2{
		color: $primaryBlack;
	}
	.card-group{
		display: flex;
		.card{
			width: 30rem;
			margin: 2rem;
			margin-left: 0;
			.card-number {
				height: 2.5rem;
				width: 2.5rem;
				font-size: 1rem;
				font-weight: 600;
				width: 2.5rem;
				display: flex;
				color: $primaryBlack;
				border-radius: 3px;
				justify-content: center;
				align-items: center;
				background-color: $primaryGreenLight;
			}
			.card-title{
				display: flex;
				align-items: center;	
				h2{
					margin: 0.5rem;
					margin-left: 0;
				}
				.best-approach{
					padding: 2px 7px 2px 7px;
					background-color: $primaryGreen;
					color: white;
					border-radius: 5px;
					font-size: 0.5rem;
				}
			}
		}
	}
	.note{
		background-color: $primaryGreyLight;
		padding:0.5rem;
		color: $primaryBlack;
	}
	.close-btn{
		margin-top: 2rem;
		padding-top: 1rem;
		border-top: 1px solid $primaryGreyLight;
		display: flex;
		justify-content: space-between;
	}
}