@import '../../Config/Theme/primary_theame.scss';

.be-pagination{
    .ant-pagination-item{
        border: none!important;
        a{
            color: $primaryGray!important;
        }
    }
    .ant-pagination-item-active{
        background-color: $primaryGreenLight!important;
        border: none!important;
        a{
            color: $primaryGreen!important;
        }
    }
    .anticon{
        color: $primaryGray!important;
    }
}