@import "../../../Config/Theme/primary_theame.scss";

.user-mgmt {
  display: flex;
  justify-content: center;
  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin: 10rem 0;
    padding-bottom: 2rem;
    width: 100%;
    div {
      padding: 30px;
      background-color: $primaryGreenLight;
      width: fit-content;
      border-radius: 50%;
      img {
        height: 47px;
        width: 47px;
      }
    }
  }
}

.list-table {
    width: 95%;
    margin: 2rem 1.5rem;
    .header-users {
      margin-bottom: 1rem;
    }
  }
