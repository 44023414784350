@import '../../../Config/Theme/primary_theame.scss';

.onboarding-step-4{
    background-color: $primaryBlueLight;
    .outer-tabs{
        margin-top: 2rem;
    }
    .inner-tabs{
        background-color: white;
        padding: 1rem;
        display: flex;
        .inner-tabs-filter{
            padding: 0 2rem;
        }
    }
    .no-metric-message{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 5rem;
    }
    .top-text{
        margin-bottom: 0rem;
        font-size: 0.8rem;
    }
}