@import "../../Config/Theme/primary_theame.scss";

.menu-bar {
  transition: all 0.5s ease-in-out;
  box-sizing: border-box;
  position: relative;
  z-index: 9;
  .version{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.5rem;
    font-size: 0.8rem;
    color: $primaryGray;
  }
  .collapse-btn {
    position: absolute;
    top: 2.5rem;
    right: -0.5rem;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
    z-index: 10;
  }

  .logo{
    cursor: pointer;
    padding-top: 1.65rem;
  }
  .menu-list {
    margin: 2.2rem auto;
    margin-bottom: 0;
    padding: 0;

    .list-itm {
      padding: 0.5rem 1.5rem;
      margin: 0 auto;
      cursor: pointer;
      transition: all 0.2s ease;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:hover {
        color: $primaryGreenHover !important;
      }

      span{
        margin: 0;
        padding: 0;
      }
    }
    .list-itm-red {
      padding: 0.5rem 1.5rem;
      padding-bottom: 0;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
