@import '../../../../../Config/Theme/primary_theame.scss';

.insights-container {
    padding: 1rem;
    height: 100%;
    border: 1px solid $primaryGreyLight;

    .title {
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 1rem;
    }

    .chart-container{
        padding: auto;
        height: 400px;
        border: 1px solid $primaryGreyLight;
    }

    .table-container{
        padding: 1rem;
        border: 1px solid $primaryGreyLight;
    }
}