@import '../../../../Config/Theme/primary_theame.scss';

.supplier-result {
  padding: 1rem;

  .top-cards {
    display: flex;
    margin-top: 1rem;
  }

  .breakdown-cards {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;

    .breakdown-card {
      .body {
        font-size: 2rem;
        font-weight: 600;
      }

      .footer {
        font-size: 0.8rem;
      }
    }
  }

  .supplier-risk-metrics {
    .risk-breakdown-box {
      border: 1px solid $primaryGreyLight;
      border-radius: 8px;
      .title {
        font-weight: 600;
        height: 56px;
        display: flex;
        align-items: center;
        padding-left: 1rem;
        background-color: $primaryGreyLight;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        font-size: 1.2rem;
      }

      .body {
        padding: 1rem;
        color: $primaryGreyDark;
        .body-row{
          display: grid;
          gap: 1rem;
          grid-template-columns: 7fr 1fr;
          margin-bottom: 1rem;
        }
      }
    }
  }
}