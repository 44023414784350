@import '../../../Config/Theme/primary_theame.scss';

.bulk-upload-drawer{
  display: flex;
  align-items: center;
}
.download-template{
  // background-color: $primaryGreenLight;
  padding: 0 1rem;
  display: flex;
  width: 100%;
  align-items: start;
  flex-direction: column;
  justify-content: center;
  .download-template-btn{
    width: 92%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.bulk-upload-result{
  .heading{
    font-size: 1rem;
    font-weight: 500;
    margin-top: 0.5rem;
  }
  .sub-heading{
    font-size: 0.9rem;
    color: $primaryGreyDark;
    margin-bottom: 0.5rem;
  }
  .rejected .heading{
    color: $primaryRedError;
  }
}