@import '../../../../Config/Theme/primary_theame.scss';

.add-custom-ef-drawer{
  p{
    color: $primaryGray;
  }
  .duplicate-box{
    p{
      color:black;
    }
    p.small-disc{
      font-size: 0.7rem;
    }
    display: flex;
    padding: 1rem;
    background-color: $primaryGreenLight;
    gap: 1rem;
    border-radius: 5px;
    align-items: flex-start;
    font-size: 0.8rem;
    margin-bottom: 1rem;
  }
}