@import '../../../../Config/Theme/primary_theame.scss';

.approve-report-box {
  background-color: $primaryBlueLight;
  min-height: 100vh;
  padding: 2rem;
  .approve-report {
    background-color: white;
    padding: 1rem;
    .section-box{
      padding: 1rem;
      >p{
      font-size: 1.5rem;
      font-weight: 600;
      color: $primaryBlack;
      margin-bottom: 2rem;
      }
      .catagory-box{
        >p{
          font-size: 1.2rem;
          font-weight: 600;
          color: $primaryBlack;
          margin-bottom: 1rem;
        }
        .topic-box{
          padding: 1rem;
          padding-bottom: 2rem;
          >p{
            font-size: 1rem;
            font-weight: 600;
            color: $primaryBlack;
            margin-bottom: 1rem;
          }
        }
        .type0-table{
          padding: 1rem;
          padding-bottom: 2rem;
        }
      }
    }
  }
}