@import '../../Config/Theme/primary_theame.scss';

.info-box {
  display: flex;
  padding: 1rem;
  background-color: $primaryGreyLight;
  border-radius: 5px;
  gap: 1rem;

  &>div>div {
    font-size: 1rem;
  }

  &>div>p {
    font-size: 0.8rem;
    font-style: italic;
  }
}