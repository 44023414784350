@import "../../../../../Config/Theme/primary_theame.scss";

.right-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    flex-direction: row;
    .last-updated {
        display: flex;
        flex-direction: column;
        font-size: 10px;
        color: $primaryGray;
    }
    .tabSearchDropdown {
        position: absolute;
        z-index: 5;
        top: 3rem;
        font-size: 0.80rem;
        width: 270px;
        max-height: 500px;
        background-color: white;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
        font-size: 0.9rem;
        overflow-y: auto;
        font-weight: 400;
        color: black;
        .list-items {
            padding: 10px 20px;
            display: flex;
            flex-direction: rows;
            font-size: 0.80rem;
            border-radius: 2px;
            &:hover {
                background-color: rgb(242, 241, 241);
                cursor: pointer;
            }
        }
    }
}

.no-data-box{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 1.5rem;
    color: $primaryGray;
    &>div{
        display: flex;
        flex-direction: column;
        align-items: center;
        .info-icon{
            height: 4rem;
            color: $primaryGray;
        }
    }
}