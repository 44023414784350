@import '../../../../../../Config/Theme/primary_theame.scss';

// ?.status-filter-dm{
    // position: absolute;
    // z-index: 4;
    // right: 2rem;
    // top: 1rem;
//     ?.status-icon-box{
//       position: relative;
//       height: 2rem;
//     }
//     ?.status-dropdown{
//       position: absolute;
//       top: 0;
//       right: 0;
//       transform: translateY(2rem);
//       width: 8rem;
//       background-color: white;
//       border-radius: 5px;
//       box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
//       //all exept last child
//       ?.status-item:not(:last-child){
//         border-bottom: 1px solid $primaryGreyLight;
//       }
//       ?.status-item{
//         padding: 0.5rem 1rem;
//         font-size: 0.85rem;
//         border-radius: 5px;
//         &:hover{
//           background-color: $primaryGreyLight;
//           cursor: pointer;
//         }
//       }
//     }
// }

.status-filter-dm{
  position: relative;
  z-index: 4;
  display: flex;
  flex-direction: row;
  gap: 1rem;

  .status-filter-select {
    .ant-select-selector {
      border: none;
      background-color: $primaryGreyLightest;
    }
    width: 135px;
    gap: 0.5rem;
  }

}