@import '../../../../Config/Theme/primary_theame.scss';


.data-mapping-table{
	.ant-select-outlined{
		width: 100%;
	}
	// .row-class{
	// 	border: 10px solid $primaryBlack !important;
	// }
	.ant-table-thead > tr > th{
		border: 1px solid $primaryGreyLight !important;
		padding: 10px;
	}
	.ant-table-expanded-row{
		padding: 0%;
	}
	.ant-table-tbody > tr > td{
		border: 1px solid $primaryGreyLight !important;
		border-radius: 8px;
		padding: 10px;
	}
	.ant-table  table { border-spacing: 0 7px; }
}


.metricSearchDropDown {
    position: absolute;
    z-index: 5;
    font-size: 0.80rem;
    width: 333px;
    max-height: 500px;
    background-color: white;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    font-size: 0.9rem;
    overflow-y: auto;
    font-weight: 400;
    .list-items {
        padding: 10px 20px;
        display: flex;
        flex-direction: rows;
        font-size: 0.80rem;
        border-radius: 2px;
        &:hover {
            background-color: rgb(242, 241, 241);
            cursor: pointer;
        }
    }
}