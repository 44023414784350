@import '../../../../Config/Theme/primary_theame.scss';

.framework-card-container{
	height: 9rem;
	width: 200px;
	border: 1px solid $primaryGreyLight;
	border-radius: 8px;
	
	.framework-card{
		// padding: 1rem 1rem 1rem 1rem;
		margin: 1rem;
		.heading{
			display: flex;
			gap: 1rem;
			align-items: center;
			.icon-container{
				width: 30px;
				height: 30px;
				border: 1px solid $primaryGreyLight;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
		.body{
			margin: 0;
			display: block;
			p{
				font-size: x-small;
			}
		}
	}
}