@import '../../Config/Theme/primary_theame.scss';

.be-tabs{
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
        color: $primaryGreen!important;
    }
    .ant-tabs-ink-bar {
        background: $primaryGreen!important;
    }
    .ant-tabs-tab-btn{
        color: $primaryGray!important;
    }
    // .ant-tabs-tab+.ant-tabs-tab{
    //     margin-left: 20px!important;
    // }
    .ant-tabs-tab{
        border: none!important;
        background-color: transparent!important;
    }
    .ant-tabs-tab-active{
        background-color: white!important;
    }
    .ant-tabs-nav{
        margin-bottom: 0px!important;
    }
    .ant-tabs-content-holder{
        background-color: white;
    }
    .ant-tabs-nav-more{
        display: none!important;
    }
    .more-icon{
        color: black!important;
    }
}