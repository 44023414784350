.ant-tabs:hover .ant-tabs-extra-content div{
    visibility: visible  !important;
}

.ant-tabs .ant-tabs-extra-content div{
	height: 1rem;
  	width: 1rem;
  	cursor: pointer;
  	visibility: hidden;
}
