@import '../../../../../Config/Theme/primary_theame.scss';
.choose-topics-matiriality{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
 &>div{
  padding: 1rem;
  margin-top: 1rem;
  border-right: 1px solid $primaryGray;
 }
 .topics{
    height: 13rem;
    overflow-y: scroll;
 }
 .topics::-webkit-scrollbar {
    width: 0.3rem;
  }
  .topics::-webkit-scrollbar-track {
    background: $primaryGreyLight;
  }
  .topics::-webkit-scrollbar-thumb {
    background: $primaryGray;
  }
}
.stpe-3-materiality{
  padding: 1rem;
}
.add-materiality-assessment{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1rem;
  
}