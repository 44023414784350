@import "../../../Config/Theme/primary_theame.scss";

.header {
    background-color: black;
    color: white;
    height: 4rem;
    position: fixed;
    z-index: 2;
    gap: 1rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 1.25rem;
    padding: 0 1rem;
    width: 100%;

    .header-right {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-left: auto;
        gap: 1rem;
        flex-direction: row;
    }

    .cancel{
        height: 1rem;
        display: flex;
        align-items: center;
        margin-left: auto;
        cursor: pointer;
    }

}

.drawer-body {
    width: 100%;
    margin-top: 4rem;
    height: calc(100vh - 4rem);
    .left-body {
        width: 18%;
        overflow-y: auto ;
        height: calc(100vh - 8rem);
        border-right: 1px solid $primaryGreyLight;
        border-bottom: 1px solid $primaryGreyLight;
        background-color: #FAFAFA;

        .footer-left-body {
            height: 4rem;
            position: fixed;
            display: flex;
            padding: 0 1rem;
            flex-direction: row;
            bottom: 0;
            width: calc(18% - 2rem);
            align-items: center;
            background-color: #FAFAFA;
        }
    }
    .left-body::-webkit-scrollbar {
        width: 5px;
    }
    .left-body::-webkit-scrollbar-track {
        background: $primaryGreyLight;
        width: 2px;
    }
    .left-body::-webkit-scrollbar-thumb {
        background: $primaryGray;
    }
    .right-body {
        width: 82%;
        .right-scroll {
            overflow-y: auto;
            height: calc(100vh - 8rem);
        }
        .right-scroll::-webkit-scrollbar {
            width: 5px;
        }
        .right-scroll::-webkit-scrollbar-track {
            background: $primaryGreyLight;
            width: 2px;
        }
        .right-scroll::-webkit-scrollbar-thumb {
            background: $primaryGray;
        }
        .footer {
            height: 4rem;
            position: fixed;
            display: flex;
            padding: 0 1rem;
            flex-direction: row;
            bottom: 0;
            width: calc(82% - 2rem);
            align-items: center;
            background-color: black;
            .footer-right {
                margin-left: auto;
                display: flex;
                align-items: center;
                gap: 1rem;
                flex-direction: row;
            }
        }
    }
}