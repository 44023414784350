@import '../../../Config/Theme/primary_theame.scss';

.event-summary{
	background-color: $primaryGreyLight;
	padding: 1rem;
	border-radius: 7px;
	display: flex;
	justify-content: space-between;
	.event-summary-item{
		padding: 0.5rem;
		color: $primaryGray;
	}
}