@import '../../../../Config/Theme/primary_theame.scss';

.mb-metric-entry-row{
	margin-top: 1rem;
	padding: 10px;
	background-color: $primaryGreyLight;
	border-radius: 7px;
	&.rejected{
		border: 1px dashed $primaryRedError;
	}
	.row-1{
		display: flex;	
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #DDDDDD;
		padding-bottom: 10px;
	}
	.row-2{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 10px;
	}
}