@import '../../../../../../Config/Theme/primary_theame.scss';

.side-filter {
    min-height: 430px;
    border-right: 1px solid $primaryGreyLight;
    padding: 16px;
    width: fit-content;
}

.side-filter-item {
    width: 153px;
    padding: 8px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 8px;
    &>div {
        width: 140px;
        display: grid;
        grid-template-columns: 1fr 5fr;
        align-items: center;
    }
}