.tracker-status {
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  font-size: 12px;
  display: inline-block;
  font-weight: 600;
}
.tracker-status.large{
  padding: 0.5rem 1rem;
  font-size: 14px;
}

.tracker-status-point{
  display: flex;
  align-items: center;
  gap: 10px;
  .point{
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
}