@import "../../../Config/Theme/primary_theame.scss";

.top-menu-bar {
    height: 55px;
    padding: 1.75rem 1.5rem;
    gap: 1rem;
    display: flex;
    align-items: center;
    .logo {
        height: 14px;
    }
    .name {
        font-weight: 600;
    }
    .ant-select-selector {
        border-radius: 4px !important;
    }
    .icon {
        width: 1rem;
        height: 1rem;
    }
    .icon-right {
        width: 0.85rem;
        height: 0.85rem;
    }
    .cascader {
        position: relative;
        .viewer {
            display: flex;
            border: 1px solid rgb(241, 239, 239);
            padding: 0.5rem;
            min-width: 200px;
            max-width: 320px;
            border-radius: 10px;
            align-items: center;

            .selectedValue {
                margin: 0 10px;
                font-size: 0.85rem;
            }

            &:hover {
                ~ .entity-selector {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
        .entity-selector {
            visibility: hidden;
            opacity: 0;
            transition:
                visibility 10ms,
                opacity 10ms;
            display: none;
            height: 28rem;
            position: absolute;
            font-size: 0.85rem;
            top: 115%;
            background-color: white;
            // border: 0.2px solid gainsboro;
            box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
            display: flex;
            z-index: 6;
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
            flex-direction: row;
            &:hover {
                visibility: visible;
                opacity: 1;
            }

            .list {
                display: flex;
                font-weight: 400;
                flex-direction: column;
                .business-groups-items {
                    visibility: hidden;
                    opacity: 0;
                    transition:
                        visibility 10ms,
                        opacity 10ms;
                    height: 27rem;
                    background-color: white;
                    // border: 0.2px solid gainsboro;
                    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
                    z-index: 6;
                    width: 250px;
                    font-weight: 400;
                    flex-direction: column;
                    display: flex;
                    position: absolute;
                    left: 100%;
                    bottom: 0%;
                    &:hover {
                        visibility: visible;
                        opacity: 1;
                    }

                    .business-group-container {
                        display: flex;
                        flex-direction: column;
                        overflow-y: auto;

                        &:hover {
                            ~ .business-unit-container {
                                visibility: visible;
                                opacity: 1;
                            }
                        }
                    }

                    .business-unit-container {
                        visibility: hidden;
                        opacity: 0;
                        transition:
                            visibility 10ms,
                            opacity 10ms;
                        height: 27rem;
                        background-color: white;
                        // border: 1px solid gainsboro;
                        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
                        z-index: 6;
                        width: 250px;
                        flex-direction: column;
                        display: flex;
                        position: absolute;
                        left: 100%;
                        border-top-right-radius: 7px;
                        border-bottom-right-radius: 7px;
                        bottom: 0%;
                        overflow-y: auto;
                        overflow-x: hidden;

                        &:hover {
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
                .list-heading {
                    width: 100%;
                    display: flex;
                    justify-content: start;
                    padding-left: 20px;
                }
                .recently-viewed-heading{
                    width: 100%;
                    display: flex;
                    justify-content: start;
                    padding-left: 20px;
                    font-weight: 600;
                    margin: 10px 0;
                }
                .recent {
                    display: flex;
                    flex-direction: column;
                    width: 250px;
                    font-weight: 400;
                }
                .list-items {
                    padding: 10px 20px;
                    display: flex;
                    flex-direction: rows;
                    border-radius: 2px;
                    &:hover {
                        background-color: rgb(242, 241, 241);
                        cursor: pointer;
                    }
                }
                .hr {
                    margin-top: 10px;
                    color: rgb(242, 236, 236);
                    background-color: rgb(242, 236, 236);
                    width: 100%;
                    opacity: 0.1;
                }
            }
        }
    }
}
