.add-event-form{
	.group{
		display: flex;
		width: 100%;
		gap: 1rem;
	}
	.form-input{
		width: 50%;
		// padding: 0 20px;
	}	
}