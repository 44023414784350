@import '../../Config/Theme/primary_theame.scss';

.be-collapse{
    width: 100%;
    border: 1px solid $primaryGreyLight;
    border-radius: 8px;
    .heading{
        padding-right: 4rem;
        padding-left: 1rem;
        display: flex;
        align-items: center;
        height: 79px;
        background-color: $primaryGreyLight;
        position:relative;
        .switch-arrow{
            cursor: pointer;
            position: absolute;
            right: 1rem;
            top: 40%;
            transform: rotateZ(90deg);
            transition: all 0.2s ease;
        }
    }
    .body-collapse{
    }
}