@import '../../../../Config/Theme/primary_theame.scss';

.insights-dashboard{
	margin-top: 2rem;
	.esg-progress{
		display: flex;
		gap: 1rem;

	}
	.body{
		display: grid;
		grid-template-columns: repeat(2,1fr);	
		gap: 1rem;
		margin-top: 2rem;

		.framework-container{
			border: 1px solid $primaryGreyLight;
			padding: 1rem;
			border-radius: 8px;
			margin: 2rem 0;
			h2{
				margin-bottom: 0.8rem;
			}
			.framework-cards{
				display: grid;
				grid-template-columns: repeat(3,1fr);
				gap: 0.5rem;
				height: 29rem;
				overflow-y: scroll;
			}
		}

		.data-status-panel{
			padding: 1rem;
			border: 1px solid $primaryGreyLight;
			border-radius: 8px;
			.ant-tabs-content-holder{
				height: 61rem;
				overflow-y: scroll;
			}
		}

		.emission-pie-chart-container{
			border: 1px solid $primaryGreyLight;
			height: 30rem;
			border-radius: 8px;
			.circle {
				height: 80px;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 80px;
				background-color: #e2f0eb;
				border-radius: 50%;
				margin-bottom: 1rem;
			}
		}
	}
}