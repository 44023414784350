@import '../../../Config/Theme/primary_theame.scss';

.welcome-screen-box {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  .welcome-screen {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    &>div {
      width: 75vw;
    }

    * {
      color: white;
    }

    p {
      line-height: 21px;
      color: $primaryGray;
    }

    .right-section {
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;

      &>div {
        position: relative;
        width: 300px;
        padding: 40px;
        border-radius: 8px;

        .img1 {
          display: none;
          height: 152px;
          width: 156px;
          position: absolute;
          top: 0;
          right: 50%;
          transform: translate(50%, -90%);
        }
      }
    }

    .left-section {
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      .welcome-to{
        color: $primaryGreen;
        margin-bottom: 0.2rem;
      }
      &>div {
        width: 400px;
        padding: 40px;
        .logo-img {
          height: 42px;
          margin-bottom: 1rem;
        }
        .heading{
          margin-bottom: 1rem;
          color: $primaryGreenDark;
          font-size: 3rem;
          width: 500px;
          line-height: 4rem;
        }
        .description{
          margin-bottom: 1.5rem;
          font-size: large;
        }
        .primary{
          width: 100%;
        }
      }
    }
  }
}