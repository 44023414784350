@import '../Theme/primary_theame.scss';


//modify antd radio and checkbox *************

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after{
    background-color: $primaryGreen;
    transform: scale(0.5);
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner{
    border-color: $primaryGreen!important;
    background-color: transparent!important;
}

.ant-checkbox-checked .ant-checkbox-inner{
    background-color: $primaryGreen;
    border-color: $primaryGreen;
}

//modify progress bar **************

.ant-progress .ant-progress-bg {
    background-color: $primaryGreen;
}

//table head z-index modification *********
.ant-table-column-title {
    z-index: 0 !important;
}

//progress bar modification *********
.ant-progress-line {
    margin-inline-end: 0;
    margin-bottom: 0;
}

// antd breadcrumb modification *********
.ant-breadcrumb li:last-child{
    color: $primaryGreen;
}

// antd drawer body modification *********
.ant-drawer-body {
    padding: 0px !important;
    overflow: hidden !important;;
}

.global-top-section {
    display: flex;
    align-items: center;
    font-weight: 600;
    gap: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $primaryGreyLight;
    .ant-form-item {
      margin-bottom: 0 !important;
    }
  }

