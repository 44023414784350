@import '../../Config/Theme/primary_theame.scss';

.login {
    height: 100vh;
    width: 100vw;
    &>div{
        width: 75vw;
    }
    p{
        line-height: 21px;
    }

    .right-section {
       @media screen and (max-width: 768px){
            width: calc(100% - 20px);
            padding: 10px;
        }

        width: 100%;
        height: 100vh;
        min-height: 740px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .form-box {
            position: relative;
            width: 300px;
            padding: 40px;
            border-radius: 8px;
            border: 2px solid $primaryGreyLight;
            .img1{
                display: none;
                height: 152px;
                @media screen and (max-width: 768px){
                    width: calc(100% - 20px);   
                }
                width:156px;
                position: absolute;
                top: 0;
                right: 50%;
                transform: translate(50%,-90%);
            }
        }
        .welcome{
            @media screen and (max-width: 768px){
                width: calc(100% - 20px);
                padding: 0 10px;
            }
            width: 380px;
            position: absolute;
            top: 3rem;
            left: 50%;
            transform: translateX(-50%);
            p{
                color: $primaryGray;
                margin-bottom: 0.5rem;
            } 
        }
    }
    .left-section{
        width: 100%;
        height: 100vh;
        min-height: 740px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $primaryGreenDark;
       
    }
}