.file-container {
    height: 188px;
    width: 160px;
    background-color: #FFFFFF;
    border: 1px solid #E2F0EB;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
    position: relative;
    color: #235E4A;

    .menu {
        position: absolute;
        right: 0.5rem;
        top: 2px;
    }

    .circle {
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        background-color: #E2F0EB;
        border-radius: 50%;
    }
    .file-name{
        width: 100px;
        line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
    }
}