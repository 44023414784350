@import '../../../../../../../Config/Theme/primary_theame.scss';

.question-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .topic-heading{
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        align-items: center;
        width: 100%;
        >p{
            font-size: 1rem;
            font-weight: 600;
            color: $primaryBlack;
        }
    }
    .right-heading{
        display: flex;
        gap: 1rem;
        align-items: center;
        margin-left: auto;
        max-width: 70rem;
    }

    .radio-list-upload {
        color: $primaryGreen;
        cursor: pointer;
        height: 1rem;
        display: flex;
        flex-direction: row;
    }

    .radio-list-container {
        display: flex;
        flex-direction: column;
        width: 100%;

        .radio-list-option {
            padding: 1rem;
            border-radius: 0.2rem;
            border: 1px solid $primaryGreyLight;
            position: relative;

            &.selected {
                background-color: $primaryGreyLightest;
            }

            .radio-list-option-text {
                font-size: 1rem;
                font-weight: 500;
                color: $primaryBlack;
            }
        }

        .radio-list-child-container {
            border: 1px solid $primaryGreyLight;
            height: 100%;
            padding: 1rem;
        }
    }
}