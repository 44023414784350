@import '../../../Config/Theme/primary_theame.scss';

.review-onboarding{
    background-color: $primaryBlueLight;
    .step-1{
        .outer-box{
            margin: 2rem;
            padding: 2rem;
            // box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
            border-radius: 8px;
            p{
                padding: 0.5rem 0;
                color: $primaryGreyDark;
            }
            .heading{
                font-weight: 600;
                color: black;
            }
            .logo{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width: 200px;
                    height: 200px;
                    object-fit: contain;
                }
            }
        }
    }
    .step-2{
        .outer-box{
            margin: 1rem;
            padding: 1rem;
            // box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
            border-radius: 8px;
        }
        .heading{
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 1rem;
        }
        .business-groups{
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
        }
    }
    .step-3{
        padding: 2rem;
    }
    .step-4{
        .outer-box{
            margin: 1rem 2rem;
            padding: 0 2rem;
            // box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
            border-radius: 8px;
        }
        .pillar{
            &>p{
                padding-top: 2rem;
                font-size: 20px;
                font-weight: 600;
            }
            .category{
                padding: 1rem;
                &>p{
                    font-size: 16px;
                    font-weight: 600;
                    border-bottom: 2px solid $primaryGreyLight;
                    padding-bottom: 0.5rem;
                }
                .group{
                    padding: 1rem;
                    &>p{
                        font-size: 14px;
                        font-weight: 600;
                    }
                    .metric-table{
                        width: auto;
                    }
                }
            }
        }
    }
}