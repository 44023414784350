.ant-tooltip {
  max-width: 250px !important;
  .ant-tooltip-content {
    border-radius: 4px;

    .ant-tooltip-inner {
      padding: 10px;
    }
  }
}

.tool-title {
  font-size: 10px;
  font-weight: 600;
  line-height: 15px;
  text-transform: uppercase;
}

.tool-body {
  color: rgb(181, 181, 181);
  font-size: 12px;
  line-height: 18px;
}
