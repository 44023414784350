@import '../../../.../../../../Config/Theme/primary_theame.scss';

.dummy-label-container {
    display: flex;
    flex-direction: row;
    width: calc(100% - 2rem);
    align-items: center;
    gap: 1rem;
    height: 2rem;
    padding: 1rem;
    background-color: $primaryGreyLightest;
}