@import '../../../../../Config//Theme/primary_theame.scss';

.supplier-form{
  padding-top: 5rem;
    .left-section{
      overflow-y: auto;
      width: 16vw;
      background-color: $primaryGreyLight;
      padding: 1rem;
      padding-bottom: 2rem !important;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      height: 100vh;
      position: fixed;
      .left-section-card{
        cursor: pointer;
        border-radius: 8px;
        padding: 1rem;
          .left-section-card-top{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .title{
              font-size: 1.1rem;
            }
            .discription{
              font-size: 0.8rem;
              color: $primaryGreyDark;
            }
          }
      }
      .left-section-card.active{
        background-color: $primaryGreenDark;
        color: white;
        .discription{
          font-size: 0.8rem;
          color: white;
        }
        .left-section-card-bottom{
          margin-top: 0.5rem;
        }
      }
    }

    .left-section-progress {
      background-color: black;
      color: white;
      width: calc(275px - 2rem);
      position: fixed;
      bottom: 0px;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .right-section{
      padding: 2rem;
      width: 70%;
      margin-bottom: 10rem;
      position: relative;
      left: 20vw;
      overflow-y: auto !important;
      .question-box:not(:last-child){
        border-bottom: 3px solid $primaryGreyLight;
        margin-bottom: 1rem;
      }
       .question-box{
         .options{
          display: flex;
          gap: 0.5rem;
          flex-direction: column;
        }
        .question{
          font-size: 1.1rem;
          margin-bottom: 0.5rem;
          font-weight: 600;
        }
      }
    }
    .progress-bar {
      background-color: black;
      color: white;
      width: 80vw;
      left: 17.7vw;
      display: flex;
      align-items: center;
      flex-direction: row;
      padding: 1rem;
      position: fixed;
      bottom: 0px;
    }


}