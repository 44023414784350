@import '../../Config/Theme/primary_theame.scss';

.tracker-card {
    border-radius: 8px;
    border: 1px solid $primaryGreyLight;
    padding: 1rem 1rem 0 1rem;
    width: 200px !important;
    height: 220px;

    .tracker-card-title{
        margin-bottom: 0.5rem;
        font-size: 1.25rem;
        font-weight: 600;
    }

    .tracker-card-progress-container {
        justify-content: center;
        height: 150px;
        width: 200px;
        position: relative;
        margin-top: 3rem;

        .tracker-card-remark {
            font-size: 1rem;
            color: $primaryGray;
            position: absolute;
            bottom: 30px;
        }

        .ant-progress-inner .ant-progress-circle-gradient {
            height: 120px;
            position: relative;
        }

        .ant-progress-text {
            top: 85px;
            position: absolute;
            font-size: 1.5rem;
            font-weight: 600;
            color: $primaryBlack;
        }

    }
}