@import '../../../Config/Theme/primary_theame.scss';

.config-container{
	margin: 1.5rem;
	.back-arrow-container{
		display: flex;
		align-items: center;
		gap: 0.5rem;
		margin-bottom: 1rem;
		cursor: pointer;
		.back-arrow{
			transform: rotate(90deg);
			height: 1rem;
			width: 1rem;
		}
	}
	.config-details{
		display: flex;
		flex-direction: column;
		gap: 2rem;
		
		.card{
			padding: 1rem;
			h3{
				margin: 0.5rem 0;
			}
			h2{
				margin-bottom: 0.8rem;
			}
			p{
				color: $primaryGray;
			}
			.status-box{
				background-color: $primaryGreyLight;
				padding: 0.3rem 1rem;
				border-radius: 3px;
				width: min-content;
				white-space: nowrap;
				display: flex;
				gap: 0.3rem;
				display: flex;
				align-items: center;
			}
		}
	
		.audit{
			margin: 1rem 0rem;
			h2{
				margin-bottom: 1rem;
			}
		}
	}
}

.integration-containter{
	display: flex;
	gap: 1.5rem;
	flex-wrap: wrap;
	margin-top: 1rem;
	.icon{
		border: 1px solid $primaryGreyLight;
		width: 3rem;
		height: 3rem;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0.3rem;
		border-radius: 6px;
	}
}
