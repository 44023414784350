@import '../../Config/Theme/primary_theame.scss';

.be-table{
    .ant-checkbox-checked .ant-checkbox-inner{
        background-color: $primaryGreen!important;
        border-color: $primaryGreen!important;
        &:hover{
            background-color: $primaryGreen!important;
        }
    }
    .ant-checkbox-indeterminate{
        border-color: $primaryGreen!important;
    }
    .ant-table-tbody >tr >td{
        border: none!important;
    }
    .ant-table-thead th{
        border: none!important;
    }
    .ant-pagination-item{
        border: none!important;
        a{
            color: $primaryGray!important;
        }
    }
    .ant-pagination-item-active{
        background-color: $primaryGreenLight!important;
        border: none!important;
        a{
            color: $primaryGreen!important;
        }
    }
    // .anticon{
    //     color: $primaryGray!important;
    // }
}

.ant-checkbox-disabled .ant-checkbox-inner {
    background-color: $primaryGreyLight!important;
    border-color: $primaryGray!important;
}