@import "../../../../../../Config/Theme/primary_theame.scss";

.event-info-modal-footer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
}
.event-info-modal-body-summary-title {
    font-size: 16px;
    font-weight: 600;
    color: $primaryGray;
}
.event-info-modal-body-summary {
    max-height: 250px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background: rgb(102, 102, 102);
    }
    &::-webkit-scrollbar-track {
        background: rgb(243, 243, 243);
        width: 2px;
    }
}