@import '../../../../../Config/Theme/primary_theame.scss';


.radio-list-parent {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .radio-question-container {
        width: 100%;

        .radio-list-question {
            font-size: 1rem;
            font-weight: 600;
            color: $primaryBlack;
            margin-bottom: 1rem;
        }

        .radio-list-container {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 100%;

            .radio-list-option {
                padding: 1rem;
                border-radius: 0.2rem;
                border: 1px solid $primaryGreyLight;
                position: relative;

                &.selected {
                    background-color: $primaryGreyLightest;
                }

                .radio-list-option-text {
                    font-size: 1rem;
                    font-weight: 500;
                    color: $primaryBlack;
                }

                .radio-list-upload {
                    color: $primaryGreen;
                    position: absolute;
                    right: 1rem;
                }
            }

            .radio-list-child-container {
                border: 1px solid $primaryGreyLight;
            }
        }

    }



}