@import '../../../Config/Theme/primary_theame.scss';

.evidence-list{
	border: 2px dashed $primaryGreyBorder;
	border-radius: 5px;
	display: flex;
	flex-wrap: wrap;
	padding: 0.5rem;
	gap: 0.25rem;
	overflow-y: scroll;
	.evidence-item{
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		padding: 1rem;	
		margin: 0.25rem 0;
		background-color: $primaryGreyLightest;
		height: 70px;
		max-width: 50px;
		&.upload-evidence{
			border-radius: 7px ;
		}
		input[type="file"] {
			display: none;
		}
		.custom-button {
			display: inline-block;
			padding: 10px 20px;
			background-color: #007BFF;
			color: white;
			border: none;
			border-radius: 5px;
			cursor: pointer;
			font-size: 16px;
		  }
	}
}