.system-folder-container {
    // border: 1px solid rgba(243, 243, 243, 1);
    border-radius: 8px;
    margin: 3rem;
    padding: 1rem;
}

.system-folder-container-tabs {
    // border: 1px solid rgba(243, 243, 243, 1);
    border-radius: 8px;
    margin: 3rem 0;
    padding: 1rem;
}

.user-folders-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.5rem;
    padding: 1rem;
    flex-direction: column;
    // border: 1px solid rgba(243, 243, 243, 1);
    border-radius: 8px;

    .top-section {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-bottom: 1rem;
    }

    .user-folder-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        width: 100%;
        margin-top: 0rem;
        padding: 1rem;
        .new-folder {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 90px;
            width: 121px;
            padding: 1rem 0;
            flex-direction: column;
            gap: 1rem;
            cursor: pointer;

            .circle {
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 55px;
                background-color: #e2f0eb;
                border-radius: 50%;
            }
        }
        .folders-section {
            display: grid;
            width: 90%;
            align-items: center;
            grid-template-columns: repeat(10, 1fr);
        }
    }
}
