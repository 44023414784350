@import '../../../../Config/Theme/primary_theame.scss';
.progress-bar-container{
	width: 100%;
	
	span{
		color: $primaryGray;
		font-size: x-small;
	}
	
	.progress-bar{
		height: 1.5rem;
		background-color: $primaryGreyLight;
		position: relative;
		border-radius: 3px;
		.icon-container{
			position: relative;
			height: inherit;
		}
	}
	.base-line-icon{
		width: 0;
		height: 100%;
		border-left: 3px solid transparent;
		border-right: 3px solid transparent;
		border-top: 6px solid $primaryGray;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}
	.target-line-icon{
		width: 0;
		height: 0;
		border-left: 3px solid transparent;
		border-right: 3px solid transparent;
		border-bottom: 6px solid $primaryBlack;
		position: absolute;
		bottom: 0;
		transform: translateX(-50%);
	}
}