@import '../../../../Config/Theme/primary_theame.scss';
.datastatus-message-container{
	border: 1px solid $primaryGreyLight;
	padding: 1rem;
	display: flex;
	gap: 1rem;
	color: $primaryGray;
	border-radius: 8px;
	margin-top: 1rem;
	.icon-container{
		display: flex;
		.icon{
			width: 30px;
			height: 30px;
			border: 1px solid $primaryGreyLight;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}