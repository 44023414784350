@import '../../../../../Config/Theme/primary_theame.scss';

.dropdown-list-parent {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .dropdown-question-container {
        width: 100%;

        .dropdown-list-question {
            font-size: 1rem;
            font-weight: 600;
            color: $primaryBlack;
            margin-bottom: 1rem;
        }
    }
}