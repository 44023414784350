@import "../../../Config/Theme/primary_theame.scss";

input.be-input{
    border-radius: 4px;
    font-size: 0.9rem;
}

.ant-input-group-wrapper-outlined .ant-input-group-addon {
    border: 1px solid $primaryGreyLight;
    font-size: 6px;
}

.ant-input-affix-wrapper-lg {
    border: 1px solid $primaryGreyLight;
    font-size: 0.85rem;
}

.ant-input-group .ant-input-affix-wrapper {
    border: 1px solid $primaryGreyLight;
    font-size: 0.9rem;
}
.ant-input-lg{
    font-size: 13px;  
}
.input-select {
    // .ant-select-single.ant-select-lg.ant-select-open .ant-select-selection-item {
    //     color: white !important;
    // }
    // .ant-select-selection-item {
    //     color: white !important;
    // }
    // .ant-select-arrow {
    //     color: white !important;
    // }
    // .be-table .anticon {
    //     color: white !important;
    // }
}