.mobile-dm {
    .hr {
        color: rgb(242, 236, 236);
        background-color: rgb(242, 236, 236);
        width: 100%;
        opacity: 0.2;
        margin-bottom: 15px;
    }
    .mobile-search-dm {
        width: calc(100% - 20px);
        margin: 10px 10px 8px 10px;
    }
}