@import '../../../../../Config/Theme/primary_theame.scss';


.checkbox-list-parent {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .checkbox-question-container {
        width: 100%;

        .checkbox-list-question {
            font-size: 1rem;
            font-weight: 600;
            color: $primaryBlack;
            margin-bottom: 1rem;
        }

        .checkbox-list-container {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 100%;

            .checkbox-list-item {
                padding: 0 1rem;
                display: flex;
                height: 4rem;
                align-items: center;
                flex-direction: row;
                gap: 1rem;
                border: 1px solid $primaryGreyLight;

                .checkbox-list-upload {
                    color: $primaryGreen;
                }

                .checkbox-list-option-text {
                    font-size: 1rem;
                    font-weight: 500;
                    color: $primaryBlack;
                }

                &.not-expandable {
                    border: none;
                    height: 2rem;
                }

                &.selected {
                    background-color: $primaryGreyLightest;
                }
            }

            .checkbox-list-child-container {
                border: 1px solid $primaryGreyLight;
            }
        }
    }

}