.supplier-step-2{
    padding: 1rem;
    h3{
      margin-bottom: 0.5rem;
    }
    .all-questions{
      display: flex;
      gap: 1rem;
      .question-section{
        width: 200px;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap:0.5rem;
      }   
    }
}