@import '../../../../Config/Theme/primary_theame.scss';

.settings-back-button{
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
  gap: 0.5rem;
  .back-btn{
    height: 1rem;
    transform: rotate(90deg);
  }
  p{
    font-size: 1rem;
  }
}