@import '../../../../Config/Theme/primary_theame.scss';

.inner-tabs-filter{
    width: 100%;
}
.current-group-metrics{
    .current-group-metrics-bar{
        display: flex;
        margin: 1rem 0;
    }
}
.empty-state{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:1rem;
    margin-top: 5rem;
    padding-bottom: 2rem;
    p{
        width: 302px;
        text-align: center;
    }
    div{
        padding: 18.5px;
        background-color: $primaryGreenLight;
        width: fit-content;
        border-radius: 50%;
        .img{
            height:35.35px;
            width: 35.35px;
        }
    }
    
}