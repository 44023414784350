@import '../../../../../Config/Theme/primary_theame.scss';

.survey-graph {
  padding: 2rem;
  display: flex;
  justify-content: center;
  .graph {
      position: relative;
      border-left: 2px solid $primaryGray;
      border-bottom: 2px solid $primaryGray;
      padding: 1rem;
      margin-bottom: 5rem;

      .imp-sh {
          position: absolute;
          top:45%;
          left: 0;
          transform: translateX(-70%) rotate(-90deg);
          font-size: 1.5rem;
          font-weight: 600;

      }

      .imp-b {
          position: absolute;
          bottom: -10%;
          left: 30%;
          font-size: 1.5rem;
          font-weight: 600;

      }

      .lp-sh {
          position: absolute;
          bottom: 5%;
          left: 0;
          transform: translateX(-120%) rotate(-90deg);
          font-weight: 400;
          color: #606060;
      }

      .lp-b {
          position: absolute;
          bottom: -10%;
          left: 5%;
          font-weight: 400;
          color: #606060;
      }

      .hp-sh {
          position: absolute;
          top: 5%;
          left: 0;
          transform: translateX(-120%) rotate(-90deg);
          font-weight: 400;
          color: #606060;
      }

      .hp-b {
          position: absolute;
          bottom: -10%;
          right: 5%;
          font-weight: 400;
          color: #606060;
      }

      .container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          .box:nth-child(1){
              border-top-left-radius: 8px;
              border-right: 1px solid #d3e5df;
              border-bottom: 1px solid #d3e5df;
          }
          .box:nth-child(2){
              border-top-right-radius: 8px;
              border-left: 1px solid #d3e5df;
              border-bottom: 1px solid #d3e5df;
          }
          .box:nth-child(3){
              border-bottom-left-radius: 8px;
              border-right: 1px solid #d3e5df;
              border-top: 1px solid #d3e5df;
          }
          .box:nth-child(4){
              border-bottom-right-radius: 8px;
              border-left: 1px solid #d3e5df;
              border-top: 1px solid #d3e5df;
          }
      }

      .box {
          background-color: #e2f0eb;
          width: 337px;
          height: 337px;
          >div {
              margin: 1rem;
              display: flex;
              flex-wrap: wrap;
              gap: 10px;
              .graph-item{
                background-color: #d3e5df;
                display: flex;
                padding: 8px 10px;
                border-radius: 34px;
                p{
                    font-size: 0.7rem;
                }
              }
          }

      }

      .compress {

          >div {
              display: grid;
              grid-template-columns: 1fr 1fr;
              height: 14rem;
              margin: 0.5rem 2rem;
              gap: 0.1rem;
              align-items: center;
              overflow: auto;
              p{
                  font-size: 0.7rem;
              }
              >div{
                  gap: 0;
                  display: flex;
              }
          }
          >div::-webkit-scrollbar {
              width: 0.2rem;
          }

          >div::-webkit-scrollbar-track {
              background: #bde1ed;
          }

          >div::-webkit-scrollbar-thumb {
              background: #888;
          }
      }

  }
}