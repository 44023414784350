@import "../../../Config/Theme/primary_theame.scss";

.onboarding-top-nav {
  background-color: $primaryBlack;
  color: $primaryGreyLightest;
  padding: 0 2rem;
  height: 81px;
  font-size: 16px;
  line-height: 24px;
  // border-top: 0.2px solid rgb(73, 73, 73);
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    margin-left: auto;
    display: flex;
    gap: 1rem;
  }
}

.onboarding-step2,.entity-manager {
  .headings {
    display: flex;
    align-items: center;
    gap: 1rem;
    button {
      margin-left: auto;
    }
  }
  .business-groups-section {
    padding: 0;
    .all-cards {
      padding: 2rem 0;
      display: flex;
      overflow-x: auto;
      gap: 1rem;
    }
    .all-cards::-webkit-scrollbar {
      height: 2px;
    }
    .all-cards::-webkit-scrollbar-track {
      background: #f1f1f1;
      height: 2px;
    }
    .all-cards::-webkit-scrollbar-thumb {
      background: $primaryGreyLight;
    }
  }
  // .business-units-section {
  //   padding: 1rem;
  // }
  .empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin: 2rem 0;
    border-bottom: 2px solid $primaryGreyLight;
    padding-bottom: 2rem;
    div {
      padding: 30px;
      background-color: $primaryGreenLight;
      width: fit-content;
      border-radius: 50%;
      img {
        height: 47px;
        width: 47px;
      }
    }
  }
}
