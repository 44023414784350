
.event-actions-empty{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 30rem;
	gap: 1rem;
	.circle {
		height: 80px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 80px;
		background-color: #e2f0eb;
		border-radius: 50%;
		margin-bottom: 1rem;
	}
}