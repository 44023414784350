@import '../../../../../Config/Theme/primary_theame.scss';

.choose-assessment-container {
    padding: 1rem;

    .connect-gresb-container {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 2rem;
        border: 1px solid $primaryGray;
        padding: 1rem;
        border-radius: 8px;

        .connect-gresb-desc {
            width: 90%;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            font-size: 0.8rem;
            font-weight: 400;
            font-family: 'Open Sans', sans-serif;
        }
    }

    .section-selection-container {
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .section-selection {
            height: 12rem;
            width: 46%;
            border: 1px solid $primaryGray;
            border-radius: 8px;
            padding: 1rem;

            .section-description {
                margin-top: 1rem;
                font-size: 0.8rem;
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
            }
        }
    }
}