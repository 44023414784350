@import '../../../Config/Theme/primary_theame.scss';

.mobile-dm-list-item{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    margin: 0 10px;
    margin-bottom: 10px;
    background-color: $primaryGreyLight;
    border-radius: 5px;

    // .mobile-dm-list-item-sn{

    // }
    // .mobile-dm-list-item-title{

    // }
}