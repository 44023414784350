@import "../../../../../Config/Theme/primary_theame.scss";

.event-list {
    padding: 1rem;

    .event-list-header {
       display: flex;
       justify-content: right;
        .event-list-header-items {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            margin-bottom: 1rem;
        }
    }

   
}