.mobile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    background-color: black;
    color: white;
    padding: 0 20px;

    .mobile-header-icons {
        display: flex;
        align-items: center;
        gap: 10px;
    }
}