@import '../../../../../Config/Theme/primary_theame.scss';

.overall-progress-container {
    padding: 1rem;
}

.timeline-year{
    margin-bottom: 0.5rem;
}

.progress-row {
    height: 2rem;
    font-weight: 500;

    .progress-label {
        display: inline-block;
        width: 150px;
    }
    .progress-value {
        color: $primaryGray;
    }
}